import './EasySoftwareTestimonials.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareTestimonialItem, {
  EasySoftwareTestimonialType,
} from './EasySoftwareTestimonialItem'

export interface EasySoftwareTestimonialsType {
  contentItemId?: string
  displayText: string
  testimonialsList: {
    contentItems: [EasySoftwareTestimonialType]
  }
}

const rootClass = `pux-testimonials`

const EasySoftwareTestimonials: FunctionComponent<EasySoftwareTestimonialsType> = (
  props
) => {
  const items = props.testimonialsList?.contentItems

  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>

      <div className={`${rootClass}-items`}>
        {items &&
          items.map((item) => {
            if (item) {
              return <EasySoftwareTestimonialItem {...item} />
            }
          })}
      </div>
    </div>
  )
}

export default EasySoftwareTestimonials
