import './EasySoftwareTestimonials.scss'

import React, { FunctionComponent } from 'react'
import PuxPicture, {
  PuxPictureType,
} from 'Shared/components/content/mediaImage/PuxPicture'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareTestimonialType {
  contentType: string
  contentItemId: string
  testimonialName: PuxWysiwygHtmlBodyType
  testimonialOccupation: PuxWysiwygHtmlBodyType
  testimonialCompany: PuxWysiwygHtmlBodyType
  testimonialText: PuxWysiwygHtmlBodyType
  testimonialPhoto: {
    mediaSelectorImage: PuxPictureType
  }
}

const rootClass = `pux-testimonials`

const EasySoftwareTestimonialItem: FunctionComponent<EasySoftwareTestimonialType> = (
  props
) => {
  return (
    <div className={`${rootClass}-item`}>
      <div className={`${rootClass}-meta`}>
        <div className={`${rootClass}-avatar`}>
          <PuxPicture
            {...props?.testimonialPhoto?.mediaSelectorImage}
            width={50}
            height={50}
            customAlt={props.testimonialName.html}
          />
        </div>
        <h4 className={`${rootClass}-name`}>{props.testimonialName.html}</h4>
        <div className={`${rootClass}-occupation`}>
          {props.testimonialOccupation.html}
        </div>
        <div className={`${rootClass}-company`}>
          {props.testimonialCompany.html}
        </div>
      </div>

      <div className={`${rootClass}-content`}>
        <div className={`${rootClass}-icon`}>
          <PuxIconBuilder icon='TestimonialIcon' />
        </div>
        <PuxWysiwyg
          className={`${rootClass}-text`}
          content={props.testimonialText}
          inline
        />
      </div>
    </div>
  )
}

export default EasySoftwareTestimonialItem
